<template>
    <div>
        <div
            :class="{
                'np-outer-switch': true,
                'np-outer-switch-on': model,
                'np-outer-switch-off': !model,
            }"
            :aria-disabled="disabled"
            @click="toggleSwitch"
        >
            <div
                :class="{
                    'np-inner-switch': true,
                    'np-inner-switch-left': !model,
                    'np-inner-switch-right': model,
                }"
            >
                <transition>
                    <icon-check
                        v-if="model"
                        class="w-3 h-auto relative top-1.5 left-1"
                    />
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import IconCheck from "~/public/icons/check.svg";

const model = defineModel<boolean>();

const props = withDefaults(
    defineProps<{
        disabled?: boolean;
    }>(),
    {
        disabled: false,
    },
);

const toggleSwitch = () => {
    if (props.disabled) {
        return;
    }

    model.value = !model.value;
};
</script>

<style scoped>
.np-outer-switch {
    @apply w-12 h-7 bg-gray-300 cursor-pointer relative;
    border-radius: 24px;
}

.np-outer-switch[aria-disabled="true"] {
    @apply opacity-50 cursor-not-allowed;
}

.np-outer-switch-off {
    @apply bg-gray-300 transition-colors duration-300;
}

.np-outer-switch-on {
    @apply bg-black transition-colors duration-300;
}

.np-inner-switch {
    @apply w-5 h-5 bg-white rounded-full absolute top-4;
}

.np-inner-switch-left {
    @apply transition-all duration-300 top-1 left-1;
}

.np-inner-switch-right {
    @apply transition-all duration-300 top-1 left-6;
}
</style>
